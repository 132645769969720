




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';

@Component({})

export default class Schedule extends Vue {
  @Prop() groupList;
  @Prop() schedule;
  @Prop() loading;

  scheduleGroup = false;
  allSelected = false;

  open(){
    this.scheduleGroup = true;
  }

  closeDialog() {
    this.scheduleGroup = false;
    this.$router.push('/schedules');
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    const groups = [];
    this.schedule.groups = [];
    if (this.allSelected) {
      this.groupList.forEach((group) => {
        groups.push(group.id);
      });
      this.schedule.groups = groups;
    }
    else {
      this.schedule.groups = [];
    }
  }

  handleChange() {
    this.allSelected = this.schedule.groups.length === this.groupList.length;
  }
  

}

